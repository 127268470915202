import {Box, Heading, HeadingProps} from '@chakra-ui/react'

import {navSize, space} from 'config/theme'

export default function Header(props: HeadingProps) {
  return (
    <Box bg='blue.500' pt={navSize}>
      <Heading
        color='white'
        maxWidth='40ch'
        mx='auto'
        p={space.lg}
        size='xl'
        textAlign='center'
        textShadow='2px 2px 10px rgba(0,0,0, 0.25)'
        {...props}
      />
    </Box>
  )
}
