import {Box} from '@chakra-ui/react'
import Header from 'components/header'
import Wistia from 'components/wistia'
import Layout from 'layouts/main'

export default function Custom404() {
  return (
    <Layout title='Page not found'>
      <Header>
        The page you requested was not found.
        <br />
        <br /> Use the links above 👆 or see the latest Conveyal features in the
        video below 👇
      </Header>
      <Box px={20} mt='-20px' pb={20}>
        <Box
          pb='30px'
          maxHeight='60vh'
          maxWidth='calc(60vh * (16 / 9))'
          mx='auto'
        >
          <Wistia boxShadow='2xl' id='blbjixs640' />
        </Box>
      </Box>
    </Layout>
  )
}
